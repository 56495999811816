<template>
  <div>
    <div class="layui-row" style="text-align: center;">

    </div>
    <fieldset class="layui-elem-field layui-field-title" style="margin-top: 20px;">


      <legend style="font-weight:500">店铺信息</legend>
    </fieldset>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>商户信息：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  v-model="ShopInfo.ShopName"  readonly="readonly" placeholder="请输入店铺全称" class="layui-input" />
        </div>
      </div>
    </div>


    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>社会信用代码：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Tydm" placeholder="请输入统一社会信用代码" class="layui-input noxg" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>注册号：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"    v-model="ShopInfo.Zch" placeholder="请输入商户注册号" class="layui-input noxg" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>公司类型：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  v-model="ShopInfo.Gslx" placeholder="请输入公司类型" class="layui-input noxg" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>店铺地址：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Spdz" placeholder="" class="layui-input" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>成立日期：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">

          <input type="text" v-model="ShopInfo.Clrq" placeholder="成立日期" readonly="readonly" class="layui-input noxg"  />
        </div>
      </div>
    </div>


    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>法定代表人：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Fddbr" placeholder="请输入法定代表人" class="layui-input noxg" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>联系方式：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Frlxfs" placeholder="请输入法定代表人联系方式" class="layui-input KXG" />

        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>身份证号码：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.SFZHM" placeholder="请输入法定代表人身份证号码" class="layui-input KXG" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>所属中队：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Street_Name" placeholder="所属中队" class="layui-input KXG" readonly="true" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>所属网格：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Street_Grid" placeholder="所属网格" class="layui-input KXG" readonly="true" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>网格管理员：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Grid_Management" placeholder="网格管理员" class="layui-input KXG" readonly="true" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>经营范围：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Jyfw" placeholder="经营范围" class="layui-input KXG" readonly="true"  />
        </div>
      </div>
    </div>
    <div  class="bnt">
      <van-button round block type="info" @click="ts()" style="font-size: 18px">我要申诉</van-button>
    </div>
  </div>
</template>

<script>
import DES from "@/assets/js/DES";
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import Emas from "@/assets/js/Emas";

export default {
name: "jbxx",
  props:{
    ShopID: {type:String, default:''}
  },
  data(){
    return{
      Info:'',
      token:'',
      ShopInfo:{
        ShopName:'',
        Tydm:'',
        Zch:'',
        Gslx:'',
        Spdz:'',
        Clrq:'',
        Fddbr:'',
        Frlxfs:'',
        SFZHM:'',
        Jyfw:'',
        Street_Name:'',
        Street_Grid:'',
        Grid_Management:''
      }
    }
  },
  mounted() {
    this.token=sessionStorage.getItem("token");

    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);

    let strData='{"ShopID":"' + this.ShopID +'"}';
    this.Info=DES.encryptDes(strData)
    // let a='{"result":"ok","msg":{"ShopName":"余姚市红曼烟酒店","Tydm":"92330281MA2EPDM21M","Zch":"330281601065370","Gslx":"个体工商户","Spdz":"舜南-舜水南路-4号","Clrq":"2011/3/7 0:00:00","Fddbr":"杨信娣","Frlxfs":"13456106492","SFZHM":"","Jyfw":"个体工商户","Street_Name":"凤山中队","Street_Grid":"东江社区网格","Grid_Management":"盛魏魏"}}\n';
    // let data=JSON.parse(a);
    // console.log(data.result)
    // if (data.result=="ok"){
    //   console.log(data.msg)
    //   if (data.msg!=''){
    //     this.ShopInfo=data.msg
    //   }else {
    //     Toast.fail('未查询到当前店铺数据');
    //     this.$router.push({
    //       path: '/index'
    //     })
    //   }
    // }
    // return;
    mgop({
      api: "mgop.will.mqsb.SjMqSb", // 必须
      host: "https://mapi.zjzwfw.gov.cn/",
      dataType: "JSON",
      type: "POST",
      data: {token:this.token,type:'1', Info: this.Info },
      appKey: "3n3b3538+2001833223+ekdhqo", // 必须
      onSuccess: (data) => {
        // alert(JSON.stringify(data))
        console.log("mgop run success login : " + JSON.stringify(data));
        if (data.data.result == "ok") {
          if (data.msg!=''){
            this.ShopInfo=data.data.msg
          }else {
            Toast.fail('未查询到当前店铺数据');
            this.$router.push({
              path: '/index'
            })
          }
        } else if(data.data.result == "401"){
          Toast.fail('token失效，请重新登录');
          this.$router.push({
            path: '/index'
          })
        }else {
          Toast.fail('数据获取失败，请重试');
          this.$router.push({
            path: '/index'
          })
        }
      },
      onFail: (err) => {
        // alert(JSON.stringify(err))
        console.log("mgop run error. error :  " + JSON.stringify(err));
        Toast.fail('数据获取失败，请重试');
        this.$router.push({
          path: '/index'
        })
      },
    });
  },
  methods:{
    ts(){
      this.$router.push({
        path: '/sjts',
        query: {
          ID: this.ShopID,
          DP_DZ:this.ShopInfo.ShopName,
          Dept_Name:this.ShopInfo.Spdz,
        }
      })
    }
  }

}
</script>

<style scoped>
@import "../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
  background:#e9f7ff;
}

.grid-demo {
  text-align: right;
}

.layui-input {
  width: 93%;
}

.layui-edge {
  right: 13% !important;
}

.layui-form-select dl {
  min-width: 93%;
}

span {
  line-height: 40px;
}

.layui-row {
  margin-top: 10px;
}

.showishide {
  display: none;
}
.layui-btn {
}
.layui-btn-warm{
  color: #d40000;
  font-weight: bold;
}


.bnt{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}


.an{ background:#307bac; border-radius:20px; color:#f3f9ff; width:80%; height:50px; line-height:50px; text-align:center;  margin:auto;margin-top:10%; font-size:20px;}
.tops {
  margin-bottom:15%;
  background-repeat: no-repeat;
  background-size: 100%;
  height:150px;
  font-size:18px;
  line-height:30px;
  padding:20px;
  color:#004e71;
}
.menu {
  min-width: 320px;
  max-width: 768px;
  /*width: 100%;*/
  height: 43px;
  background: #9bdcfa;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
  overflow-x: scroll;
  overflow-y: hidden;
  padding:15px;
}
.menu td {
  padding-left: 10px;
}
legend {
  color:#0580AD;
}
</style>