<template>
  <div>
    <fieldset class="layui-elem-field layui-field-title" style="margin-top: 20px;">


      <legend style="font-weight:500">门前三包</legend>
    </fieldset>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>商户信息：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.ShopName" readonly="readonly"  class="layui-input" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>店铺地址：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.Spdz" class="layui-input" readonly="readonly" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>门前三包：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" v-model="ShopInfo.mqsb" class="layui-input" readonly="readonly" />
        </div>
      </div>
    </div>

<!--    <div class="layui-row">-->
<!--      <div class="layui-col-xs4">-->
<!--        <div class="grid-demo grid-demo-bg1">-->
<!--          <span>门前三包：</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="layui-col-xs8">-->
<!--        <div class="grid-demo">-->
<!--          <div class="layui-upload-list">-->
<!--            <img class="layui-upload-img" id="demo3" style="width: 100%; height: 100%;" runat="server"  />-->
<!--            <p id="demoText3"></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="layui-row">-->
<!--      <div class="layui-col-xs4">-->
<!--        <div class="grid-demo grid-demo-bg1">-->
<!--          <span>证件照片：</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="layui-col-xs8">-->
<!--        <div class="grid-demo">-->
<!--          <div class="layui-upload-list">-->
<!--            <img class="layui-upload-img" id="demo4" style="width: 100%; height: 100%;" runat="server"  />-->
<!--            <p id="demoText4"></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div style="width: 100%; height: 30px; background-color: #0066e475; margin-top: 10px; color: white; line-height: 30px; padding-left: 5%;">近四周店铺评分记录</div>
    <div style="width: 99%; border: dashed 2px #c2ccd8;" v-html="ShopInfo.ShopPF">

    </div>
  </div>
</template>

<script>
import DES from "@/assets/js/DES";
import {Toast} from "vant";
import {mgop} from "@aligov/jssdk-mgop";
import Emas from "@/assets/js/Emas";

export default {
name: "mqsb",
  props:{
    ShopID: {type:String, default:''}
  },
  data(){
    return{
      Info:'',
      token:'',
      ShopInfo:{
        ShopName:'',
        Spdz:'',
        mqsb:'',
        ShopPF:''
      }
    }
  }, mounted() {
    this.token=sessionStorage.getItem("token");

    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);

    let strData='{"ShopID":"' + this.ShopID +'"}';
    //console.log(strData)
    //alert(strData)
    this.Info=DES.encryptDes(strData)
    // console.log(this.Info)
    // let a="{\"result\":\"ok\",\"msg\":{\"ShopName\":\"余姚市红曼烟酒店\",\"Spdz\":\"舜南-舜水南路-4号\",\"mqsb\":\"\",\"ShopPF\":\"<table style='width:100%;height:100%;'><tr><th style='text-align:center;'>评分人</th><th style='text-align:center;'>商铺得分</th><th style='text-align:center;'>评分时间</th></tr><tr><td style='text-align:center;'>杨忠平</td><td style='text-align:center;'>85</td><td style='text-align:center;'>2021-09-04</td></tr><tr><td style='text-align:center;'>杨忠平</td><td style='text-align:center;'>85</td><td style='text-align:center;'>2021-08-22</td></tr><tr><td style='text-align:center;'>杨忠平</td><td style='text-align:center;'>90</td><td style='text-align:center;'>2021-08-11</td></tr><tr><td style='text-align:center;'>杨忠平</td><td style='text-align:center;'>90</td><td style='text-align:center;'>2021-08-06</td></tr></table>\"}}";
    // let data=JSON.parse(a);
    // console.log(data.result)
    // if (data.result=="ok"){
    //   console.log(data.msg)
    //   if (data.msg!=''){
    //     this.ShopInfo=data.msg
    //   }else {
    //     Toast.fail('未查询到当前店铺数据');
    //     this.$router.push({
    //       path: '/index'
    //     })
    //   }
    // }
    // return;
    mgop({
      api: "mgop.will.mqsb.SjMqSb", // 必须
      host: "https://mapi.zjzwfw.gov.cn/",
      dataType: "JSON",
      type: "POST",
      data: {token:this.token,type:'2', Info: this.Info },
      appKey: "3n3b3538+2001833223+ekdhqo", // 必须
      onSuccess: (data) => {
        // alert(JSON.stringify(data))
        console.log("mgop run success login : " + JSON.stringify(data));
        if (data.data.result == "ok") {
          if (data.msg!=''){
            this.ShopInfo=data.data.msg
          }else {
            Toast.fail('未查询到当前店铺数据');
            this.$router.push({
              path: '/index'
            })
          }
        } else if(data.data.result == "401"){
          Toast.fail('token失效，请重新登录');
          this.$router.push({
            path: '/index'
          })
        }else {
          Toast.fail('数据获取失败，请重试');
          this.$router.push({
            path: '/index'
          })
        }
      },
      onFail: (err) => {
        // alert(JSON.stringify(err))
        console.log("mgop run error. error :  " + JSON.stringify(err));
        Toast.fail('数据获取失败，请重试');
        this.$router.push({
          path: '/index'
        })
      },
    });
  },
}
</script>

<style scoped>
@import "../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
  background:#e9f7ff;
}

.grid-demo {
  text-align: right;
}

.layui-input {
  width: 93%;
}

.layui-edge {
  right: 13% !important;
}

.layui-form-select dl {
  min-width: 93%;
}

span {
  line-height: 40px;
}

.layui-row {
  margin-top: 10px;
}

.showishide {
  display: none;
}
.layui-btn-warm{
  color: #d40000;
  font-weight: bold;
}

.menu {
  min-width: 320px;
  max-width: 768px;
  /*width: 100%;*/
  height: 43px;
  background: #9bdcfa;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
  overflow-x: scroll;
  overflow-y: hidden;
  padding:15px;
}
.menu td {
  padding-left: 10px;
}
legend {
  color:#0580AD;
}
</style>