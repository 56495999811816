<template>
<div class="weui-form-preview" >
  <van-tabs v-model="active" color="#1989FA">
    <van-tab  title="基本信息"><ProblemShop :ShopID="ShopID"></ProblemShop></van-tab>
    <van-tab title="门前三包"><ProblemSb :ShopID="ShopID"></ProblemSb></van-tab>
    <van-tab title="劝导记录"><ProblemQd :ShopID="ShopID"></ProblemQd></van-tab>
    <van-tab title="商家申诉"><ProblemSs :ShopID="ShopID"></ProblemSs></van-tab>
    <van-tab title="用户投诉"><ProblemTs :ShopID="ShopID"></ProblemTs></van-tab>
  </van-tabs>
</div>
</template>

<script>
import ProblemShop from "@/components/jbxx";
import ProblemSb from "@/components/mqsb";
import ProblemQd from "@/components/qdjl";
import ProblemSs from "@/components/ssjl";
import ProblemTs from "@/components/tsjl";
import Emas from "@/assets/js/Emas";
export default {
name: "shopInfo",
  components: {ProblemShop,ProblemSb,ProblemQd,ProblemSs,ProblemTs},
  data(){
    return{
      active:'1',
      ShopID:'',
    }
  },
  created() {
    this.ShopID=this.$route.query.ID
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  }
}
</script>

<style scoped>

</style>