<template>
  <div>
    <!--    <van-search-->
    <!--        v-model="Search.Title"-->
    <!--        show-action-->
    <!--        placeholder="请输入标题"-->
    <!--        @search="onSearch"-->
    <!--        @cancel="onCancel"-->
    <!--    />-->
    <fieldset class="layui-elem-field layui-field-title" style="margin-top: 20px;">


      <legend style="font-weight:500">劝导记录</legend>
    </fieldset>
    <van-list>
      <div>
        <div v-if="flag === true">
          <div class="weui-cells" style="margin-top: 10px;" v-for="(item, i) in list" :key="i" >
            <a class="weui-cell weui-cell_access" href="javascript:;" @click="toInfo(item.ID)">
              <div class="weui-media-box__hd">
                <img src="../assets/images/quandao.png" alt="" style="width:30px;margin-right:10px;">
              </div>
              <div class="weui-cell__bd">
                <p><span  class="bt">{{item.QDDX_MC}}</span><br><span>劝导时间：{{item.Date_OP}}</span></p>
              </div>
              <div class="weui-cell__ft"></div>
            </a>
          </div>
        </div>
        <van-empty description="无数据，请重新查询" v-else/>
      </div>
    </van-list>
  </div>
</template>

<script>
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import DES from "@/assets/js/DES";
import Emas from "@/assets/js/Emas";

export default {
  name: "qdjl",
  props:{
    ShopID: {type:String, default:''}
  },
  data() {
    return {
      list: [],
      type: '',
      value: '',
      flag: true,
      loading: false,   //是否处于加载状态
      finished: false,  //是否已加载完所有数据
      isFinished: false,
      pageCount:'',  //总页数
      token:'',
      Phone:'',
      Info:''
    };
  },
  mounted() {
    this.token=sessionStorage.getItem("token");
    this.fetchData();
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },
  methods:{
    async fetchData() {
      let strData='{"ShopID":"' + this.ShopID +'"}';
      // console.log(strData)
      // alert(strData)
      this.Info=DES.encryptDes(strData)
      // console.log(this.Info)
      // let a='{"result":"ok","msg":{ "Date":"1","rows":[{"QDDX_MC":"余姚市马俊拉面店","ID":"12","ZFLB_Name":"劝导","This_Status_Name":"结束","CLJG_Name":"警告","Date_OP":"2021/6/2 9:24:00"}]}}\n';
      // let data=JSON.parse(a);
      // console.log(a)
      // console.log(data)
      // console.log(data.msg.rows)
      // if (data.result=="ok"){
      //   if (data.msg.rows.length>0){
      //     this.flag=true
      //     this.list=data.msg.rows
      //   }else {
      //     this.flag=false
      //   }
      //
      // }
      // return;

      mgop({
        api: "mgop.will.mqsb.SjMqSb", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: {token:this.token,type:'3', Info: this.Info },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          // alert(JSON.stringify(data))
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {
            if (data.data.msg.rows.length>0){
              this.flag=true
              this.list=data.data.msg.rows
            }else {
              this.flag=false
            }
          }else if(data.data.result == "401"){
            Toast.fail('token失效，请重新登录');
            this.$router.push({
              path: '/index'
            })
          } else {
            this.flag=false
          }
        },
        onFail: (err) => {
          // alert(JSON.stringify(err))
          console.log("mgop run error. error :  " + JSON.stringify(err));
          this.flag=false
        },
      });
    },toInfo(ID) {
      if(ID !== "") {
        this.$router.push({
          path: '/mqsbQd',
          query: {
            ID: ID,
          }
        })
      }
    },
  }
}
</script>

<style scoped>
@import "../assets/css/layui.css";

legend {
  color:#0580AD;
}
</style>